<template>
    <picture>
        <source v-if="selfHosted" :srcset="sourceSet" type="image/webp" />
        <img
            :id="props.id"
            ref="image"
            :src="props.src"
            :class="computedCssClass"
            :alt="props.alt"
            :title="props.title"
            :height="computedHeight"
            :width="computedWidth"
            loading="lazy" />
    </picture>
</template>
<script setup>
const props = defineProps({
    src: { type: String, default: null },
    cssClass: { type: String, default: null },
    alt: { type: String, default: null },
    title: { type: String, default: null },
    height: { type: String, default: null },
    width: { type: String, default: null },
    id: { type: String, default: null },
    multiResolution: {
        // Enabling multiResolution requires that selfHosted is true, this is so
        // we can use the same component for external or selfHosted images.
        // if multiResolution == true && selfHosted == true, we can assume the
        // higher resolution versions of the image are available.
        type: Boolean,
        default: false,
    },
    selfHosted: {
        // This component was originally used to upgrade jpg company images
        // to webp so the default value is true, however, this component
        // is now used as well in places where the image may be selfHosted
        // or it may come from a third-party site. If the image is not
        // selfHosted, we must use the exact image URL specified in the data.
        // If the image is selfHosted, we can assume the webp version is
        // available.
        type: Boolean,
        default: true,
    },
});

const imageRef = ref(null);

const sourceSet = computed(() => {
    // The image is not selfHosted, use the image src as-is
    if (!props.selfHosted) {
        return props.src;
    }

    // We have multi resolution images in addition to the 1x image
    if (props.multiResolution) {
        return `${props.src}.webp, ${props.src}.1.5x.webp 1.5x, ${props.src}.2.0x.webp 2x`;
    }

    // The image is selfHosted so we can upgrade to the webp
    return `${props.src}.webp`;
});

const computedHeight = computed(() => {
    return props.height || imageRef.value?.naturalHeight;
});

const computedWidth = computed(() => {
    return props.width || imageRef.value?.naturalWidth;
});

const computedCssClass = computed(() => {
    return `object-contain object-left ${props.cssClass ? props.cssClass : ""}`;
});
</script>
